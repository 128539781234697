<template>
	<div id="update-asso-wapper">
		<h2>修改院系信息</h2>
		<ul class="addasso-additem">
			<li>
				<p class="textleft">名称</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputName"
					:disabled = "true"
					placeholder="院系名称"
				></el-input>
			</li>
            <li>
				<p class="textleft">院系组别</p>
				<div class="addasso-additem-input">
					<el-radio disabled v-model="inputGroup" label="甲">甲组</el-radio>
					<el-radio disabled v-model="inputGroup" label="乙">乙组</el-radio>
				</div>
			</li>
			<li>
				<p class="textleft">院系联系人</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputContactName"
					placeholder="联系人"
				></el-input>
			</li>
			<li>
				<p class="textleft">联系电话</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputContactPhone"
					placeholder="联系电话"
				></el-input>
			</li>
			<li>
				<p class="textleft">联系邮箱</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputContactEmail"
					placeholder="联系邮箱"
				></el-input>
			</li>
			<li>
				<p class="textleft">院系介绍</p>
				<el-input
					class="addasso-additem-input"
					type="textarea"
					placeholder="请输入内容（可留空）"
					v-model="inputDetail"
					maxlength="100"
					show-word-limit
				/>
			</li>
			<li>
				<el-button
					style="margin-top: 0.5rem; width: 10rem"
					type="primary"
					@click="submit()"
					>提交</el-button
				>
			</li>
		</ul>
	</div>
</template>

<style scoped>
.addasso-additem {
	list-style-type: none;
	display: block;
	width: 40rem;
	margin: auto;
}
.addasso-additem >>> li {
	margin-bottom: 5px;
}
.addasso-additem .textleft {
	text-align: left;
	position: relative;
	padding-left: 8px;
	border-left: 4px solid #1fcab3;
}
.addasso-additem-input {
	width: 100%;
}
li .addbtn {
	margin: 0.7rem 5%;
	width: 35%;
}
</style>

<script>
import axios from "axios";
import qs from "qs";
import mixin from "../../mixins/universalmixins.js";
export default {
	name: "UpdateInfo",
	mixins: [mixin],
	data() {
		return {
            inputName: "",
            inputGroup: "",
			inputContactName: "",
			inputContactPhone: "",
			inputContactEmail: "",
			inputDetail: "",
		};
	},
	beforeMount() {
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
			axios({
				url: "/api/admin/asso/get",
				method: "GET",
				params: {
					type: "dept",
					personal: 1,
				}
			}).then((res) => {
				// console.log(res.data.data[0]);
                this.inputName = res.data.data[0].deptname;
                this.inputGroup = res.data.data[0].deptgroup;
				this.inputContactName = res.data.data[0].deptcontact.name;
				this.inputContactPhone = res.data.data[0].deptcontact.phone;
				this.inputContactEmail = res.data.data[0].deptcontact.email;
				this.inputDetail = res.data.data[0].deptinfo;
			}).catch((err) => {
				this.messageBox(
                    "获取院系信息失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
                    "error"
                    );
                console.log("Get dept info Failed");
                console.log(err.response);
			});
		}
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
		async submit() {
			let i = 0;
			while ((this.userinfo == null) && i < 70) {
				i++;
				await this.sleep(100);
			}
			if (this.userinfo != null) {
				if (this.userinfo.userbelong != undefined) {
				axios({
					url: "/api/admin/asso/update",
					method: "POST",
					headers: {
						"content-type": "application/x-www-form-urlencoded",
					},
					data: qs.stringify({
						type: "dept",
						id: this.userinfo.userbelong,
						deptcontact: JSON.stringify({
							name: this.inputContactName,
							phone: this.inputContactPhone,
							email: this.inputContactEmail,
						}),
						deptinfo: this.inputDetail,
					}),
				})
				.then(async () => {
					// console.log(res);
					await this.messageBox("修改院系信息成功", "success");
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					this.messageBox(
						"修改院系信息失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Change Info Failed");
					console.log(err.response);
				});
				} else {
					this.messageBox("获取用户信息失败，请检查网络连接或重新登录", "error");
				}
			} else {
				this.messageBox("获取用户信息失败，请检查网络连接或重新登录", "error");
			}
		},
	},
};
</script>